<template>
  <caddie-income-attend-status :is-income-page="true"/>
</template>

<script>
import CaddieIncomeAttendStatus from '@/views/game-progress/caddie-arrangement/CaddieIncomeAttendStatus';

export default {
  name: 'CaddieIncomeStatus',
  components: {CaddieIncomeAttendStatus},
};
</script>
